import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Img from 'gatsby-image'

class Competition extends React.Component {
  render () {
    return (
      <Layout>
        <SEO
          title="Competition"
        />
        <article>
          <div className="c-masthead c-masthead--center">
            <div className="container">
              <div className="row">
                <div className="c-masthead__title-container">
                  <h1 className="c-masthead__title">Competition</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="c-cta-content">
            <div className="container">
              <div className="row">
                <div className="c-cta-content__title-container">
                  <h2 className="c-cta-content__title">The Edge Advent Calendar Competition</h2>
                  <div className="c-cta-content__content cms-content">
                    <p>This December, we want to say thank you for all the incredible support The Edge has received this year. We’re giving our fans the opportunity to win some unbelievable prizes EVERY. SINGLE. DAY.</p>
                  </div>
                </div>
                <div className="c-cta-content__content-container">
                  <div className="cms-content">
                    <p>You’ll have a chance to win The Edge DVDs, posters, mini cricket bats &amp; soundtrack vinyl signed by the likes of James Anderson, Alastair Cook, Kevin Pietersen and more from our fantastic lineup! (See the full prize list below)</p>
                    <p>Prizes that money can’t buy! The perfect Christmas present for yourself or your cricket-mad loved ones.</p>
                    <p>Follow our social profiles to make sure you don’t miss a day <a href="https://twitter.com/theedgefilm2019" target="_blank">Twitter</a>, <a href="https://www.facebook.com/theedgefilm2019" target="_blank">Facebook</a> &amp; <a href="https://www.instagram.com/theedgefilm2019/" target="_blank">Instagram</a>.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-charity-listing c-charity-listing--nomargin-top">
            <div className="container">
              <div className="row">
                <div className="c-charity-listing__item">
                  <div className="c-charity-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.mega.childImageSharp.fluid
                      }
                      alt=""
                    />
                  </div>
                  <div className="c-charity-listing__item-content-container">
                    <h2 className="c-charity-listing__item-title">
                      Master Competition
                    </h2>
                    <div className="c-charity-listing__item-content cms-content">
                      <ul>
                        <li>MEGA PRIZE: Full signed DVD, Full signed poster, Signed Vinyl &amp; Wristband</li>
                        <li>Runs December 1st - December 24th</li>
                        <li>Info on how to enter is included on the post</li>
                        <li>The winner will be picked on December 24th and contacted via DM</li>
                        <li>Full T&amp;Cs below</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="c-charity-listing__item">
                  <div className="c-charity-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.daily.childImageSharp.fluid
                      }
                      alt=""
                    />
                  </div>
                  <div className="c-charity-listing__item-content-container">
                    <h2 className="c-charity-listing__item-title">
                      Daily Competition
                    </h2>
                    <div className="c-charity-listing__item-content cms-content">
                      <ul>
                        <li>From 2nd - 24th December a total of 17 competitions will be run with 25 prizes to be won on our <a href="https://twitter.com/theedgefilm2019" target="_blank">Twitter</a>, <a href="https://www.facebook.com/theedgefilm2019" target="_blank">Facebook</a> &amp; <a href="https://www.instagram.com/theedgefilm2019/" target="_blank">Instagram</a> pages</li>
                        <li>Info on how to enter will be included in each post</li>
                        <li>Each prize draw competition will end on the day it was posted at 11:59pm GMT</li>
                        <li>Winners will be contacted via DM</li>
                        <li>Full T&amp;Cs below</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-social c-social--nomargin-top">
            <div className="container">
              <div className="row">
                <div className="c-social__social-link-container">
                  <ul className="c-social__social-link-list">
                    <li className="c-social__social-link-item">
                      <a className="c-social__social-link c-social__social-link--twitter" href="https://twitter.com/theedgefilm2019" target="_blank">
                        <span className="c-social__social-link-text">Twitter</span>
                        <i className="c-social__social-link-icon icon-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="c-social__social-link-item">
                      <a className="c-social__social-link c-social__social-link--facebook" href="https://www.facebook.com/theedgefilm2019" target="_blank">
                        <span className="c-social__social-link-text">Facebook</span>
                        <i className="c-social__social-link-icon icon-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="c-social__social-link-item">
                      <a className="c-social__social-link c-social__social-link--instagram" href="https://www.instagram.com/theedgefilm2019/" target="_blank">
                        <span className="c-social__social-link-text">Instagram</span>
                        <i className="c-social__social-link-icon icon-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="c-cta-content">
            <div className="container">
              <div className="row">
                <div className="c-cta-content__title-container c-cta-content__title-container--highlight">
                  <h2 className="c-cta-content__title">Full Prize List</h2>
                  <div className="c-cta-content__content cms-content">
                    <ul>
                      <li>
                        8 x Regular DVD + signed mini bats combo prizes
                        <ul>
                          <li>1 x Signed by James Anderson</li>
                          <li>1 x Signed by Kevin Pietersen</li>
                          <li>1 x Signed by Jonathan Trott</li>
                          <li>1 x Signed by Paul Collingwood</li>
                        </ul>
                      </li>
                      <li>
                        12 x Signed DVD &amp; Poster combo prizes
                        <ul>
                          <li>1 x Signed by Andrew Strauss</li>
                          <li>1 x Signed by Alastair Cook</li>
                          <li>1 x Signed by James Anderson</li>
                          <li>1 x Signed by Ian Bell</li>
                          <li>1 x Signed by Steven Finn</li>
                          <li>1 x Signed by Paul Collingwood</li>
                          <li>1 x Signed by Jonathan Trott</li>
                          <li>1 x Signed by Graeme Swann</li>
                          <li>1 x Signed by Monty Panesar</li>
                          <li>1 x Signed by Tim Bresnan</li>
                          <li>1 x Signed by the film’s director Barney Douglas</li>
                          <li>1 x Signed by Felix White from The Maccabees</li>
                        </ul>
                      </li>
                      <li>
                        5 x The Edge Soundtrack Vinyl
                        <ul>
                          <li>Signed by Felix White from The Maccabees</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="c-cta-content__content-container">
                  <h2 className="c-cta-content__title">Competition Terms &amp; Conditions</h2>
                  <div className="c-cta-content__content cms-content">
                    <ul>
                      <li>Competition details form part of these terms and conditions.</li>
                      <li>Entry is open to entrants worldwide.</li>
                      <li>Proof of identity may be required.</li>
                      <li>Use of a false name or address will result in disqualification.</li>
                      <li>All entries must be made directly by the person entering the competition.</li>
                      <li>Entries made online using methods generated by a script, macro or the use of automated devices will be void.</li>
                      <li>No responsibility can be accepted for entries lost, delayed or corrupted, or due to computer error in transit.</li>
                      <li>The Master Competition will run from 1st December - 24th December. A winner will be selected from entries submitted (in the format detailed on the Master Competition post on each social platform)</li>
                      <li>The Daily Competition will run from 2nd - 24th December, a total of 17 competitions will be run with 25 prizes to be won. Winners will be selected from entries submitted (in the format detailed on each social platform) between 10am GMT and 11.59pm GMT on each day of the prize draw competitions.</li>
                      <li>Winners will be selected at random from entrants across all social platforms (Facebook, Twitter, Instagram).</li>
                      <li>The prizes are as stated, are not transferable to another individual and no cash or other alternatives will be offered.</li>
                      <li>Prizes are subject to availability.</li>
                      <li>The promoters reserve the right to amend or alter the terms of competitions and reject entries from entrants not entering into the spirit of the competition.</li>
                      <li>In the event of a prize being unavailable, the promoter reserves the right to offer an alternative prize of equal or greater value.</li>
                      <li>The winner(s) agree(s) to the use of their name, photograph and disclosure of country of residence and will co-operate with any other reasonable requests by The NOAH MEDIA GROUP LTD relating to any post-winning publicity.</li>
                      <li>Unless stated otherwise the winner(s) will be drawn at random from all correct entries received by the closing date stated within the promotional material.</li>
                      <li>Reasonable efforts will be made to contact the winner(s). If the winner(s) cannot be contacted, or are unable to comply with these terms and conditions, the Promoter reserves the right to offer the prize to the next eligible entrant drawn at random.</li>
                      <li>Confirmation of the prize will also be made in writing to the winner(s).</li>
                      <li>Failure to respond and/or provide an address for delivery, or failure to meet the eligibility requirements may result in forfeiture of the prize.</li>
                      <li>Where applicable, the decision of the judges is final based on the criteria set out in the promotion and no correspondence will be entered into over this decision. Competitions may be modified or withdrawn at any time.</li>
                      <li>The Promoter is The Noah Media Group Ltd, 32 Clerkenwell Green, London, EC1R 0DU.</li>
                      <li>In the event of a discrepancy between these standard terms and conditions and the details in the promotional material (or any other terms and conditions provided/referred to at the time of entry), the details of the promotional material (and any other terms and conditions provided/referred to at the time of entry) shall prevail.</li>
                      <li>A list of winners will be available by writing into the promoter at the Promoter's address no earlier than six weeks (unless otherwise stated) after the close of the competition. Only the surname and county of the winners will be disclosed.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export const fluidImage = graphql`
  fragment competitionImage on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    mega: file(relativePath: { eq: "competition/mega-prize.jpg" }) {
      ...competitionImage
    }
    daily: file(relativePath: { eq: "competition/daily-prize.jpg" }) {
      ...competitionImage
    }
  }
`

export default Competition
